import React from 'react';
import PageLayout from '../../components/Layout/Page';
import EventsShell from '../../components/Event/Shell';

const EventsPage = () => {
  return (
    <PageLayout
      title="Worldwide events"
      excerpt="Find a workshop in your local area"
    >
      <EventsShell filterFn={e => !e.show_in_student_calendar} />
    </PageLayout>
  );
};

export default EventsPage;
